var documentComponent;
var datalayerService;
function openManuModal(el) {
    var manuEl = el.parentElement;
    datalayerService.setModal(true);
    if (manuEl.id) {
        documentComponent.openManufacturerModal(manuEl.id);
    } else {
        documentComponent.openManufacturerModal(manuEl.getAttribute('data-pid'));
    }

    
}

